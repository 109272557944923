jQuery(document).ready(function() {

  $('.image-sets').slick({
    dots: true,
    arrows: true,
    infinite: false,
    slide: '.image-set',
    appendDots: '.dots-wrapper',
    prevArrow: '<svg class="svg-arrow-left"><use xlink:href="#svg-arrow-left"></use></svg>',
    nextArrow: '<svg class="svg-arrow-right"><use xlink:href="#svg-arrow-right"></use></svg>',
  });

});