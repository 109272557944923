// CANTFIX: clicking the error label fires blur which hides the
// label and moves cursor out of input

var nameError    = 'How should we address you?';
var emailError   = "This Email doesn't seem quite right.";
var phoneError   = 'What is the best way to call you?';
var messageError = 'How can we help you?';
var interestError = 'What are you interested in?';

var parentSelector = '.field';
var formErrorClass = 'error';
var formValidClass = 'valid';

var focusParent = function ($elem) {
  $elem.parents(parentSelector).addClass('focus');
};

var unfocusParent = function ($elem) {
  $elem.parents(parentSelector).removeClass('focus');
};

var validationOptions = {
  onkeyup: false,
  rules: {
    contact_email: {
      customemail: true
    }
  },
  messages: {
    contact_name: nameError,
    contact_email: emailError,
    contact_home_phone: phoneError,
    contact_phone: phoneError,
    contact_message: messageError,
    contact_interested: interestError,
    contact_interest: interestError
  },
  highlight: function (element, errorClass, validClass) {
    unfocusParent($(element));
    $(element).parents(parentSelector)
      .addClass(errorClass)
      .removeClass(validClass);
  },
  unhighlight: function (element, errorClass, validClass) {
    unfocusParent($(element));
    $(element).parents(parentSelector)
      .removeClass(errorClass)
      .addClass(validClass);
  }
};

var initInputFocus = function ($form) {
  $form.find('input, textarea')
    .on('focus', function () {
      focusParent($(this));
    })
    .on('input', function () {
      $(this).parents(parentSelector)
        .removeClass(formErrorClass)
        .removeClass(formValidClass)
        .removeClass('with-suggestion')
        .find('.suggestion').hide();
    })
    .on('blur', function () {
      unfocusParent($(this));
    });
};

var initExpandingTextareas = function ($form) {
  if (!$.isFunction(jQuery.fn.expanding)) {
    console.log('expanding-textareas is required');
    return true;
  }

  if (!$form.length || !$form.is(':visible')) {
    return true;
  }

  $form.find('textarea').each(function () {
    var $textarea = $(this);

    if ($textarea.parent().hasClass('expanding-wrapper')) {
      return true;
    }

    $textarea.expanding();
  });
};

var initMailcheck = function ($form, emailClass) {
  if (!$.isFunction(jQuery.fn.mailcheck)) {
    console.log('mailcheck is required');
    return true;
  }

  $form.find('.' + emailClass).each(function () {
    var $email = $(this);
    var closeLink = '<svg class="close-icon" viewBox="0 0 25 25">' +
                    '<use xlink:href="#close-icon"></use></svg>';
    var suggestionHtml =
      '<p class="suggestion">Did you mean: ' +
      '<a href="javascript:void(0)" class="suggestion-link"></a> ? ' +
      '<a href="javascript:void(0)" class="close-link">' + closeLink +
      '</a></p>';

    $email.parents(parentSelector).append(suggestionHtml);

    $email.on('blur', function () {
      $(this).mailcheck({
        suggested: function ($element, suggested) {
          var $suggestion     = $element.siblings('.suggestion');
          var $suggestionLink = $suggestion.find('.suggestion-link');
          var $field          = $suggestion.parents(parentSelector);

          $suggestionLink.text(suggested.domain);
          $suggestionLink.data('value', suggested.full);
          $suggestion.show();
          $field.addClass('with-suggestion');
          $field.removeClass(formValidClass).removeClass(formErrorClass);
          $field.find('input').removeClass('flash-on');
        },
        empty: function ($element) {
          return;
        }
      });
    });
  });

  $('.suggestion .close-link').on('click', function () {
    var $suggestion = $(this).parent();
    var $field      = $suggestion.parents(parentSelector);

    $suggestion.hide();
    $field.find('input').valid();
    $field.removeClass('with-suggestion');
  });

  $('.suggestion .suggestion-link').on('click', function () {
    var $suggestionLink = $(this);
    var newValue        = $suggestionLink.data('value');
    var $suggestion     = $suggestionLink.parent();
    var $field          = $suggestion.parents(parentSelector);
    var $input          = $field.find('input');

    if (!newValue) { return; }

    $input.addClass('flash-off');
    $suggestion.hide();
    $field.find('input').valid();
    $field.removeClass('with-suggestion');

    setTimeout(function () {
      $input.removeClass('flash-off');
      $input.val(newValue);
      $input.addClass('flash-on');
    }, 200);
  });
};

var initValidation = function (contactFormClass, options) {
  if (!$.isFunction(jQuery.fn.validate)) {
    console.log('jquery-validation is required');
    return true;
  }

  $.validator.addMethod(
    'customemail',
    function(value, element) {
      return /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(value);
    },
    "Sorry, I've enabled very strict email validation"
  );

  options        = options || {};
  var emailClass = options.emailClass || 'contact-form-email';
  var debug      = options.debug || false;

  validationOptions.debug = debug;

  $('form.' + contactFormClass).each(function () {
    var $form = $(this);

    $form.validate(validationOptions);
    initInputFocus($form);
    initExpandingTextareas($form);
    initMailcheck($form, emailClass);
  });
};
