setupVideoCaption = function ($video, videoType) {
  var me_mode = 'auto';
  var is_firefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
  if (is_firefox) {
    me_mode = 'auto_plugin';
  }
  new MediaElementPlayer($video, {
    type: videoType,
    mode: me_mode,
    startVolume: 0.8,
    AndroidUseNativeControls: true,
    iPhoneUseNativeControls: true,
    iPadUseNativeControls: true,
    pluginPath: "//d4xmq39929kw8.cloudfront.net/mediaelement-2.14/",
    plugins: ['flash'],
    flashName: 'flashmediaelement-cdn.swf'
  });
};

$(function () {
  $('.video-fragment').each(function () {
    var $video    = $(this).find('video');
    var videoType = $video.data('type');

    if ($video.length) {
      setupVideoCaption($video, videoType);
    }

    $(this).fitVids();
  });
});
